import React from 'react';



import Grid from '@mui/material/Grid2';

import {
  isMobile
} from "react-device-detect";

import WindHeroes from './Wind-Heroes.js';
import MTile from './M-Tile.js';
import GhostBurner from './Ghost-Burner.js';
import TTile from './Time-Tile.js';
import WordDragger from './Word-Dragger.js';
import TimeTile from './Time-Tile.js';



class Apps extends React.Component {




    render() {
      
      
      if(isMobile)
        return (
          <div className = "App-header">
            <Grid container spacing={1}>
              <Grid size={12} className = "Grid-summary-item">
                <WindHeroes summary = {true}/>
              </Grid>
              <Grid size={12} className = "Grid-summary-item">
                <WordDragger summary = {true}/>
              </Grid>
              <Grid size={12} className = "Grid-summary-item">
                <MTile summary = {true}/>
              </Grid>
              <Grid size={12} className = "Grid-summary-item">
                <TimeTile summary = {true}/>
              </Grid>
              <Grid size={12} className = "Grid-summary-item">
                <GhostBurner summary = {true}/>
              </Grid>
              
            </Grid>
          </div>
        );
        else
        {
          return(
            <div className = "App-header">
            <Grid container spacing={1}>
              <Grid size={4} className = "Grid-summary-item">
                <WindHeroes summary = {true}/>
              </Grid>
              <Grid size={4} className = "Grid-summary-item">
                <WordDragger summary = {true}/>
              </Grid>
              <Grid size={4} className = "Grid-summary-item">
                <MTile summary = {true}/>
              </Grid>
              <Grid size={4} className = "Grid-summary-item">
                <TimeTile summary = {true}/>
              </Grid>
              <Grid size={4} className = "Grid-summary-item">
                <GhostBurner summary = {true}/>
              </Grid>
            </Grid>
          </div>
          );
        }
      
        
     
    }
      
  }
  

export default Apps;

import React from 'react';

import BaseApp from './BaseApp.js';
import AppSummary from './AppSummary.js';

class TimeTile extends React.Component {

    render() {

      var shot1  = require('./images/ttile/shot_1.jpg');
      var shot2  = require('./images/ttile/shot_2.jpg');
      var shot3  = require('./images/ttile/shot_3.jpg');
      var shot4  = require('./images/ttile/shot_4.jpg');


      const shortDescription =
      `The clock is ticking tick tock, swipe, then again, tick tock, swipe again, your pulse is racing.
      `;
      const longDescription = `
      Time Tile Merge is an addictive game testing intelligence and resolve while you race the clock. Swipe and merge alike tiles on the clock. You get rewards when you achieve superior ranks. All the while, you listen to the tick tock of the clock.

        Swipe two A tiles, you get a B tile; merge two B tiles, you get a C tile. Keep swiping and merging. 

        The race has begun. Swipe left, then merge up. Swipe up, then merge right. Swipe right, then merge down. Swipe down, then merge left. Keep it up, until the race ends.

        Smart players will get well passed the letter ‘F’ tile, but most will never reach this level.

        The clock is ticking tick tcok, swipe, then again, tick tock, swipe again, your pulse is racing.

        Challenge your friends; tik tok; score higher; tick tock. The clock winds down; tick tock. Race the clock; tick tock. Achieve higher letters, tick tock!

        Let the race begin!`;

      if(this.props.summary === true)
      return (
        <AppSummary appName = 'Time-Tile'
                        shortDescription = {shortDescription} 
                        longDescription = {longDescription}
                        appStoreLocation = "https://apps.apple.com/us/app/id1515164716"
                        route = {"/apps/time-tile"}
                        shot = {shot1}
                        />
      );
      else
      return(
        <BaseApp appName = 'Time-Tile'
                        shortDescription = {shortDescription} 
                        longDescription = {longDescription}
                        videoId = "VFDTN--T07g"
                        appStoreLocation = "https://apps.apple.com/us/app/id1515164716"
                        shots = {[shot1,shot2,shot3,shot4]}
                        />
      
      );
      }
   
  }
  

export default TimeTile;

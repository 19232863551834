import React from 'react';

import BaseApp from './BaseApp.js';
import AppSummary from './AppSummary.js';

class WordDragger extends React.Component {

    render() {

      var shot1  = require('./images/word_dragger/shot_1.jpg');
      var shot2  = require('./images/word_dragger/shot_2.jpg');
      var shot3  = require('./images/word_dragger/shot_3.jpg');
      var shot4  = require('./images/word_dragger/shot_4.jpg');


      const shortDescription =
      `The beauty of this word game is that it's not just about placing a word randomly; it's about strategy and trivia. Think ahead, see the connections between words.
      `;
      const longDescription = `The beauty of this word game is that it's not just about placing a word randomly; it's about strategy and trivia. You need to think ahead, see the connections between words, and anticipate what might come next. The theme of the level plays a key role here.

The best of trivia and excitement. You get words in your tray that you drag onto a board of mystery interconnected words. Points are awarded for each word you successfully place, by size. If you meet the minimum score, then you unlock the next level.

Words relate to one another in a trivia theme. Each word in your tray appears on the board at least once. Reach a minimum score to reach the next level. You are dragging whole words onto the board!

As you progress through the levels, the board becomes more intricate, with words weaving in and out of each other, creating a tapestry of lexical challenge. Sometimes, you'll find yourself stuck, staring at the screen, your mind racing through the dictionary. But that's when the real magic happens—when you find that one word that fits perfectly, bridging the gap; and suddenly, the puzzle starts to unravel before your eyes.

Word theme levels are being added.`;

      if(this.props.summary === true)
      return (
        <AppSummary appName = 'Word Dragger'
                        shortDescription = {shortDescription} 
                        longDescription = {longDescription}
                        appStoreLocation = "https://apps.apple.com/us/app/word-dragger/id6642682592"
                        playStoreLocation = "https://play.google.com/store/apps/details?id=com.abnormalhead.worddrag"
                        route = {"/apps/word-dragger"}
                        shot = {shot1}
                        />
      );
      else
      return(
        <BaseApp appName = 'Word Dragger'
                        shortDescription = {shortDescription} 
                        longDescription = {longDescription}
                        appStoreLocation = "https://apps.apple.com/us/app/word-dragger/id6642682592"
                        playStoreLocation = "https://play.google.com/store/apps/details?id=com.abnormalhead.worddrag"
                        shots = {[shot1,shot2,shot3,shot4]}
                        />
      
      );
      }
   
  }
  

export default WordDragger;

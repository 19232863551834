import React from 'react';

import Grid from '@mui/material/Grid2';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

import AppSlider from './Slideshow.js';

import WindHeroes from './Wind-Heroes.js';
import MTile from './M-Tile.js';
import TTile from './Time-Tile.js';
import GhostBurner from './Ghost-Burner.js';
import OfficeUndead from './Office-Undead.js';
import WordDragger from './Word-Dragger.js';

import AboutUs from './About-Us.js';
import ContactUs from './Contact-Us.js';
import News from './News.js';
import Privacy from './Privacy.js';
import Support from './Support.js';
import Apps from './Apps.js';


import {
  createBrowserRouter,
  RouterProvider,
  useNavigate
} from "react-router-dom";


import Logo from "./images/ah_logo.jpg";
import './AbnormalHead.css';


import {
  isMobile
} from "react-device-detect";
import TimeTile from './Time-Tile.js';



class AbnormalHead extends React.Component {
 
  

constructor(props) {
  super(props);

  //this.router_config = require("./sitemap-routes").default;
  this.state = {
    isDesktop: false, 
    breakpoint:0
  };


  this.classes = {paper: {
    //padding: theme.spacing(2),
    textAlign: 'center',
    //color: theme.palette.text.secondary,
  },
  root: {
    flexGrow: 1,
  }
};

  this.updatePredicate = this.updatePredicate.bind(this);
}




componentDidMount() {
  this.updatePredicate();
  window.addEventListener("resize", this.updatePredicate);
  //ReactGA.pageview(window.location.pathname);
}

componentWillUnmount() {
  window.removeEventListener("resize", this.updatePredicate);
}

updatePredicate() {
  this.setState({ isDesktop: window.innerWidth > 800 });
  if(window.innerWidth > 800)
  {
    this.isDesktop = true;
  }
  else
  {
    this.isDesktop = false;
  }
  
}

render()
{ 

  

  var slides = undefined;

  if(!isMobile)
  {
    slides = [
    {
      key:0,
      appname: 'Wind Heroes',
      subtitle: '3d immersive Augmented Reality',
      href: '/apps/wind-heroes',
      img: '/slider-wh-desktop.jpg',
    },
    
    {
      key:1,
      appname: 'M-Tile',
      subtitle: 'An addictive letter tile game',
      href: '/apps/m-tile',
      img: '/slider-mtile-desktop.jpg',
    },
    
    ];
    }
    else
    {
      slides = [
        {
          key:0,
          appname: 'Wind Heroes',
          subtitle: '3d immersive Augmented Reality',
          href: '/apps/wind-heroes',
          img: '/slider-wh-mobile.jpg',
        },
        
        {
          key:1,
          appname: 'M-Tile',
          subtitle: 'An addictive letter tile game',
          href: '/apps/m-tile',
          img: '/slider-mtile-mobile.jpg',
        },
        
        ];
    }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <AppSlider slides={slides} className="App-slideshow"/>,
    },
    {
      path: "/about-us",
      element: <AboutUs/>,
    },
    {
      path: "/contact-us",
      element: <ContactUs/>,
    },
    {
      path: "/news",
      element: <News/>,
    },
    {
      path: "/privacy",
      element: <Privacy/>,
    },
    {
      path: "/apps",
      element: <Apps/>,
    },
    {
      path: "/apps/",
      element: <Apps/>,
    },
    {
      path: "/support",
      element: <Support/>,
    },
    {
      path: "/apps/wind-heroes",
      element: <WindHeroes/>,
    },
    {
      path: "/apps/word-dragger",
      element: <WordDragger/>,
    },
    {
      path: "/apps/m-tile",
      element: <MTile/>,
    },
    {
      path: "/apps/time-tile",
      element: <TimeTile/>,
    },
    {
      path: "/apps/ghost-burner",
      element: <GhostBurner/>,
    },
    {
      path: "/*",
      element: <AppSlider slides={slides} className="App-slideshow"/>
    },
  ]);

  <Grid className = 'App-logo-row' xs={9}><Button className = 'App-logo-row' color="inherit"  size="small" href = '/'><h1 className = 'App-logo-text'>Abnormal Head</h1></Button></Grid>
      
  
  return (
    <div className={this.classes.root}>
      <header className="App-header">
      <Grid container spacing={0}>
      <Grid size={12} className = 'App-logo-row'><a className = 'App-logo-row' href = '/'><img className = 'App-logo-row' src = {Logo} alt = "Abnormal Head" align="middle"/></a></Grid>
      <Grid size={12} className = 'ah-tag-line'>"The bleeding edge of gaming reality. Addictive games, augmented reality and all around entertainment"</Grid>
          <Grid size={12}>
              <Toolbar className = 'App-toolbar' disableGutters = {true}  variant = 'dense'>
                <Button color="inherit" href = '/'>Home</Button>
                <Button color="inherit" href = '/apps/'>Apps</Button>
                <Button color="inherit" href = '/contact-us'>Contact Us</Button>
                <Button color="inherit" href = '/support'>Support</Button>
              </Toolbar>
          </Grid>
          <Grid size={4} className = 'App-appbar'>
          <Button classes={{ label: 'App-button' }} color="inherit" href = '/apps/wind-heroes'>Wind Heroes AR</Button>
          </Grid>
          <Grid size={4} className = 'App-appbar'>
          <Button classes={{ label: 'App-button' }} color="inherit" href = '/apps/word-dragger'>Word Dragger</Button>
          </Grid>
          <Grid size={4} className = 'App-appbar'>
          <Button classes={{ label: 'App-button' }} color="inherit" href = '/apps/m-tile'>M-Tile</Button>
          </Grid>
          
          <Grid size={12}>
            
              <React.StrictMode>
                <RouterProvider router={router}/>
              </React.StrictMode>
           
          </Grid>

          
          
      </Grid>
      </header>
      <Grid size={12} className = "page-spacer">
        &nbsp;   
      </Grid>
     
      <Grid size={12} className = "page-spacer">
        &nbsp;
      </Grid>
      
      <footer className="App-footer">
        <Grid container spacing = {1}>
          <Grid size={12}>
            <Toolbar className = 'App-toolbar'>
              <Button color="inherit" href = '/contact-us'>Contact Us</Button>
              <Button color="inherit" href = '/privacy'>Privacy Policy</Button>
              <Button color="inherit" href = '/support'>Support</Button>
            </Toolbar>
          </Grid>
         
          <Grid size={12}>
            <li id="copyright" className="App-copyright ">© 2024 &nbsp;<a className = "App-copyright-link" href="http://www.abnormalhead.com">Abnormal Head LLC</a>&nbsp; All Rights Reserved. </li>
          </Grid>
        </Grid>
      </footer>

      
  </div>
  );
}
}

export default AbnormalHead;


import React from 'react';

import Grid from '@mui/material/Grid2'; 

import {
  isMobile
} from "react-device-detect";

class Support extends React.Component {

  render() {
    const description =
    `Support
    You may contact us with support issues at support@abnormalhead.com
    
    Please be sure to include the name of the app and the device/platform that you are using. Please be as descriptive as possible and include the steps leading to any problems you are experiencing.

    Privacy Policy
    We do serve ads within the apps that we publish, however we do not share any personal, information. No information that reveals identity of any kind is captured or shared.
    `;


    if(!isMobile)
    {
      //console.log("Desktop or Tablet");
      return (
        <div>
          <Grid container spacing={1}>
            <Grid size={12}>
              <div className = 'Game-short-description'>
                {description}
              </div>
            </Grid>
          </Grid>
        </div>
      );
    }
    else
    {
      //console.log("Mobile");
      return(
        <div>
          <Grid container spacing={1}>
            <Grid size={12}>
              <div className = 'Game-short-description'>
                {description}
              </div>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}
  

export default Support;

import React from 'react';

import './index.css';
import AbnormalHead from './AbnormalHead';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';
import { BrowserHistory, Action, Location } from "history";


import ReactDOM from 'react-dom';


const trackingId = "UA-125051814-1"; // Replace with your Google Analytics tracking ID
//ReactGA.initialize(trackingId);
ReactDOM.trackingId = trackingId;



const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<AbnormalHead/>);



serviceWorker.unregister();

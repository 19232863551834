import React from 'react';

import BaseApp from './BaseApp.js';
import AppSummary from './AppSummary.js';


class WindHeroes extends React.Component {

  constructor(props) {
    super(props);
  }
    render() {
      
      var shot1  = require('./images/wind_heroes/shot_1.jpg');
      var shot2  = require('./images/wind_heroes/shot_2.jpg');
      var shot3  = require('./images/wind_heroes/shot_3.jpg');
      var shot4  = require('./images/wind_heroes/shot_4.jpg');


      const shortDescription =
      `Play the game, get INTO it it ….. literally! Be the wind and blow your heroes toward victory!
      `;
      const longDescription = `
      There has been a schism in the world of Wolves. As is with life, there are good Wolves and evil Wolves. In the 3d immersive augmented reality game, you use your wolves as heroes. Guide hem into position, and they will curl themselves into their helmets and drop to the ground. Now you can blow them either using your face in augmented reality mode AR; or simply by commanding the winds to blow.
      \n
      You begin the game by starting out with the hard headed Wood Hero. He has a hard head and can do plenty of damage, but you sill soon realize that other kinds of heroes have some pretty amazing talents. Some of them are uniquely talented to specific situations. You will also see certain objects such as the ramp will help you.
      \n
      The Bomb Hero comes in really handy when you need the force of an explosion when he reaches his target. The Boomerang Hero comes back into the opposite direction when the wind stops. There are heroes that vaporize, reverse gravity, twist objects, create nuclear explosions and so much more.
      \n
      As you play these ingenious levels, you will also find there are ramps, wormholes, transporters and a whole host of objects that become a part of the challenges.
      \n
      Although this game was created with Augmented Reality in mind (AR), the AR is optional. Hold the blow button down and the wind will blow all the same. We had a great deal of fun creating this game, but we want to keep developing and enhancing its features. The Heroes, ramps, wormholes, transporters and all the fun is just as great without the AR, though when your alone, we urge you to at least try it!
        `;

    if(this.props.summary === true)
      return (
        <AppSummary appName = 'Wind Heroes AR'
                        shortDescription = {shortDescription} 
                        longDescription = {longDescription}
                        route = {"/apps/wind-heroes"}
                        appStoreLocation = "https://itunes.apple.com/us/app/wind-heroes/id1034623811?ls=1&amp;mt=8"
                        shot = {shot1}
                        />
      );
      else
      return(
        <BaseApp appName = 'Wind Heroes AR'
                        shortDescription = {shortDescription} 
                        longDescription = {longDescription}
                        videoId = "8ikzCKbYQLE"
                        appStoreLocation = "https://itunes.apple.com/us/app/wind-heroes/id1034623811?ls=1&amp;mt=8"
                        shots = {[shot1,shot2,shot3,shot4]}
                        tailVideoId = "_QFsTNZCh8c"
                        />
      
      );
    }
    

  }
  

export default WindHeroes;

import React from 'react';

import BaseApp from './BaseApp.js';
import AppSummary from './AppSummary.js';

class GhostBurner extends React.Component {

    render() {
      
      var shot1 = require('./images/ghost_burner/shot_1.jpg');
      var shot2 = require('./images/ghost_burner/shot_2.jpg');
      var shot3 = require('./images/ghost_burner/shot_3.jpg');
      var shot4 = require('./images/ghost_burner/shot_4.jpg');


      const shortDescription =
      `It was all of ghosts and shadows. These ghosts are of a special variety.
      `;
      const longDescription = `
      It was all of ghosts and shadows. These ghosts are of a special variety. They can be vanquished if combined, but still remain. Those ghosts who remain are dangerous. The special fire that join these ghosts are but when the ghosts become one. 

      Wondering about this graveyard is very dangerous. Those ghosts that carry the letter, beware. Add more ghosts, and perhaps they will destroy themselves.

      Ghosts can be your friends if used correctly. As the graves and orange cats think about the next move so must you! Wonder the graves if you must, but beware.

      To poke the ghost is very dangerous. There is more about these graves than you may realize. The shadows linger, and the higher lettered ghosts grimace. The lower lettered ghosts fly with glee, for they have been out of the graves the longest. they leave shadows, but the cats have foretold this in the old scripture.

      Only the very special and talented minds can solve for the complex patterns these ghosts live. Each ghost is a tile and burns with-in a letter sequence. Line them up so they burn well! Over time, the game gets faster and more difficult. When the ghosts combine, they increase rank. How high can you go?

      Hold the line against these cute little ghosts as they try and burn trails in accordance of the letters they present.  `;

      
   
    if(this.props.summary === true)
      return (
        <AppSummary appName = 'Ghost Burner'
                        shortDescription = {shortDescription} 
                        longDescription = {longDescription}
                        appStoreLocation = "https://apps.apple.com/us/app/ghost-burner/id1480135509?ls=1"
                        route = {"/apps/ghost-burner"}
                        shot = {shot1}
                        />
      );
      else
      return(
        <BaseApp appName = 'Ghost Burner'
                        shortDescription = {shortDescription} 
                        longDescription = {longDescription}
                        videoId = "7t9bI7_68zY-4"
                        appStoreLocation = "https://apps.apple.com/us/app/ghost-burner/id1480135509?ls=1"
                        shots = {[shot1,shot2,shot3,shot4]}
                        />
      
      );
    }

  }
  

export default GhostBurner;

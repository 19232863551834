import React from 'react';
import Grid from '@mui/material/Grid2'; 

import {
  isMobile
} from "react-device-detect";

class ContactUs extends React.Component {

    render() {
      /*
      const paragraph1 =
      `The Abnormal Head story is simple. What would it be like to create entertaining apps that is always a little ahead of everyone else? How can we develop apps that is distinctly different?
        Before Augmented Reality was a spoken term, we were exploring the combination of the real world and the virtual one. From the days when we were little we all played with toy soldiers or dolls. We were lost in our world, but the toys were real, they existed in a physical way in our own reality. that is what has been lost in 
      `;
*/
      const paragraph1 =
      `Contact
      We are always interested to listen to the ideas and suggestions from the general public.
      `;

      const paragraph2 =
      `For general inquiries, you may contact us at info@abnormalhead.com
      `;

      const paragraph3 =
      `For inquiries regarding press releases, you may contact us at press@abnormalhead.com
      `;

      const paragraph4 =
      `Support
      You may contact us with support issues at support@abnormalhead.com
  
      Privacy Policy
      We do serve ads within the apps that we publish, however we do not share any personal, information. No information that reveals identity of any kind is captured or shared.
      
      `;


      if(!isMobile)
      {
        return (
          <div>
            <Grid container spacing={1}>
              <Grid size={12}>
                <div className = 'Game-short-description'>
                  {paragraph1}<br/>
                  {paragraph2}<br/>
                  {paragraph3}<br/>
                  {paragraph4}<br/>
                </div>
              </Grid>
            </Grid>
          </div>
        );
      }
      else
      {
        return(
          <div>
            <Grid container spacing={1}>
              <Grid size={12}>
                <div className = 'Game-short-description'>
                  {paragraph1}<br/>
                  {paragraph2}<br/>
                  {paragraph3}<br/>
                  {paragraph4}<br/>
                </div>
              </Grid>
            </Grid>
          </div>
        );
      }
    }
}

export default ContactUs;

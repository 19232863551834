import React from 'react';


import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';



export default function AppSummary(props) {
  
  let navigate = useNavigate();

  const handleClick = () => {
    //setExpanded(!expanded);
    //console.log("Click");
    const appRoute = props.route;
    navigate(appRoute);
  };

  const cardClass = "backgroundColor #147777; display: flex; flex-direction: row; justify-content: left; font-size: calc(15px + 2vmin); color: rgb(0, 0, 0); font-family: 'Oswald', sans-serif; align-items: center;";
   

  return (
    <Card onClick={handleClick} className = {cardClass}>
      <CardHeader title={props.appName} subheader={props.appName}/>
      <CardMedia component="img" height="190" image={props.shot} alt={props.appName} title={props.appName}/>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.shortDescription}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
      </CardActions>
    </Card>
    
  );
}